import './Cards.css'
import Card from '../card/Card'
import React from 'react'

function Cards(props) {
	return (
		<div className="Cards">
			<div className="flex-row">
				<div className="flex-col">
					<Card asset="$JAYN" web3={props.web3} plan="1" warning={'* Variable Claim Node'} withdrawTime={'Any Time'} />
				</div>

<div className="flex-col">
					<Card asset="$JAYN" web3={props.web3} plan="4" warning={'* Fixed Claim Node'} withdrawTime={'End of Plan'} />
				</div>
				
			</div>
		</div>
	)
}

export default Cards
