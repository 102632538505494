import React, { useState, useEffect } from 'react'
import './Hero.css'
import bckImg from '../fondo-balance.jpg'
import Spinner from 'react-bootstrap/Spinner'
import { totalStaked } from '../utils.js'

function Hero(props) {
	const [loading, setLoading] = useState(false)
	const [totalStake, setTotalStake] = useState(0)
	

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true)
				if (props.web3 === undefined) return
				const _totalStake = await totalStaked(props.web3)
				
				setTotalStake(_totalStake)
			
				setLoading(false)
			} catch (e) {
				console.error(`Error at Hero ${e.message}`)
			}
		}
		init()
	}, [props])

	return (
		<div className="Hero">
			{loading === true ? <Spinner /> : null}

			<div
				id="basic-info"
				style={{
					backgroundImage: 'url(' + bckImg + ')',
					backgroundSize: '200%',
					backgroundPosition: 'center left',
				}}>
				{props.asset === 'BNB' ? ( //for BNB
					<div>
	
						<p>1) Create your node and enjoy a High APY Yield</p>
						<p>2) Stake directly your yield</p>
						<p>Minimal deposit: 50 $JAYN, no maximal limit</p>
						<p>Earnings every moment, withdraw any time your staked tokens.</p>
					<p>Nodes will open at <a href="https://bscscan.com/block/countdown/7012940">Block Countdown</a></p>
	
	</div>
				) : (
					//for BUSD
					<div>
						<p>1) Create your node and enjoy a High APY Yield</p>
						<p>2) Stake directly your yield</p>
						<p>Minimal deposit: 50 $JAYN, no maximal limit</p>
						<p>Earnings every moment, withdraw any time (if you use capitalization of interest you can withdraw only after end of your deposit or anytime before at 30% penalty)</p>
	<p>Nodes will open at <a href="https://bscscan.com/block/countdown/7012940">Block Countdown</a></p>
		</div>
				)}
			</div>

			<div id="balance">
				<div>
					<p className="d-flex flex-row">
						Total Value of Nodes
						
					</p>
					<h2>{totalStake.toFixed(1)}</h2>
				</div>

				

				
			</div>
		</div>
	)
}

export default Hero
